@font-face {
  font-family: "Lemonmilk Bold";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("lemonmilk-bold.ttf");
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}
.page {
  height: 100%;
  overflow-y: auto;
}

.formIframe {
  height: calc(100% - 124px);
  width: 100%;
}

.page >>> .form-box {
  margin-top: 0 !important;
}

.refitPartnerTitle {
  width: 60%;
  padding: 5% 20% 2%;
  text-align: center;
  font-family: "Lemonmilk Bold";
  color: #454545;
}

.top-container {
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 10px 0;
}

.vertical-border {
  height: 80px;
  width: 1px;
  border-right: 2px solid lightgray;
}
.top-image {
  max-width: 100px;
}

@media only screen and (max-width: 800px) {
  .title {
    font-family: "Lemonmilk Bold", serif;
    font-size: 3vmin;
    text-align: right;
    color: black;
    width: 30vw;
    margin-left: 8vw !important;
  }
}

.title {
  font-family: "Lemonmilk Bold", serif;
  font-size: 3vmin;
  text-align: right;
  color: black;
  width: 30vw;
  margin-left: 15vw;
}

@media only screen and (max-width: 425px) {
  .title {
    font-family: "Lemonmilk Bold", serif;
    font-size: 3vmin;
    text-align: left;
    color: black;
    width: 70vw;
    margin-left: 15vw;
  }

  .top-container {
    display: flex;
    gap: 50px;
    align-items: center;
  }
  .top-image {
    max-width: 100px;
  }
}
